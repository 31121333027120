import {$http} from "@/plugins";

const state = {
    Nor_links: [],
    Ads_links: []
}

const mutations = {
    SET_LINKS(state, links) { 
        const Normal = []; const Ads = [];
        for(let el of links){
            switch(el.using_times.Type){
                case 'Nor':
                    Normal.push(el);
                    break;
                case 'Ads':
                    Ads.push(el);
                    break;
            }
        }
        state.Nor_links = Normal;
        state.Ads_links = Ads;
    }
}

const actions = {
    getLinks({commit}, username) { // TODO User: {username}
        $http.post("/v1/links/list", {username}).then(res => {
            if (res.data) {
                commit('SET_LINKS', res.data);
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
