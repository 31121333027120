import { $http } from "@/plugins";
import vue from 'vue';
const state = {
    userinfo: {
        name: '',
        role: '',
        username: ''
    }
};

const mutations = {
    SET_USERINFO(state, user) {
        // state.userinfo = user;
        Object.keys(user).forEach(key => {
            vue.set(state.userinfo, key, user[key]);
        });
    },
};

const actions = {
    getUserinfo({commit, state}, user){
        $http.post('/v2/accountlog/config', user).then(res=>{
            if(res.code === 0){
                commit('SET_USERINFO', res.data);
            }
        })
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
