import { $http } from "@/plugins";
import vue from 'vue'
const state ={
    groups: {}
};

const mutations = {
    SET_GROUPS(state, groups) {
        const { key, data } = groups;
        vue.set(state.groups, key, data);
    },
    SET_TOP(state, group){
        var key;
        if(group.role || group.creator){
            const role = group.role;
            const creator = group.creator;
            group = group.gr;
            key = group.appname + creator;
        }
        const grList = state.groups[key];
        const index = grList.findIndex(gr => gr.id === group.id);
        if(index > -1){
            grList.splice(index, 1);
        }
        grList.unshift(group);
    }
}

const actions ={
    getUserGroup({commit, state}, ID) { // ID: {appname, username}
        const { appname, username, role } = ID;
        const key = appname + username;
        $http.post('/v1/group/list', ID).then(res => {
            if (res.data) {
                commit('SET_GROUPS', {
                    key,
                    data: res.data
                });
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
