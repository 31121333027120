import i18n from "@/i18n";
import {$http} from "@/plugins";

const state = {
    devices: [],
    version: '',
    queue: []
}

function getState(status) {
    let state;
    switch (+status) {
        case -1:
            state = i18n.t('disconnected')
            break
        case 0:
            state = i18n.t('idle')
            break
        case 1:
            state = i18n.t('running')
            break
        case 2:
            state = i18n.t('updating')
    }
    return state
}

const mutations = {
    SET_DEVICES(state, list) {
        if (state.devices.length === 0) {
            state.devices = list.map(item => {
                item.state = getState(item.status)
                return item
            })
        } else {
            list.forEach(item => {
                const device = state.devices.find(d => d.deviceName === item.deviceName);
                if (device) {
                    Object.assign(device, item);
                    device.state = getState(item.status)
                } else {
                    item.state = getState(item.status)
                    state.devices.unshift(item);
                }
            });
        }
    },
    SET_VERSION(state, version) {
        state.version = version
    },
    SET_DEVICES_ACCOUNT(state, param) {
        const {deviceName, account} = param;
        const device = state.devices.find(devi => devi.deviceName === deviceName);
        device.account = account;
        console.log("dev-Acc", device);
    },
    SET_QUEUE(state, val){
        const {deviceName, info} = val;
        if(info.length){
            const script = info.map(item => {
                return {
                    title: item.scriptname,
                    groups: item.groupName.map(name => name+",\n"),
                    appname: item.app,
                    status: item.status,
                    link: item.env.groups
                }
            })
            const check = state.queue.findIndex(q => q.deviceName === deviceName)
            if(check >= 0){
                state.queue[check].script = script;
                state.queue[check].status = info[0].status;
                state.queue[check].qStatus = info[0].qStatus;
                console.log("in Q, Queue: ",state.queue);
            }
            else {
                const status = info[0].status;
                const qStatus = info[0].qStatus;
                state.queue.push({deviceName, script, status, qStatus});
                console.log("not in Q, Queue: ",state.queue);
            }
        } else {
            const check = state.queue.findIndex(q => q.deviceName === deviceName);
            if(check >= 0){
                state.queue[check] = [];
                console.log("Device ", deviceName, "is empty");
            }
            state.queue = state.queue.filter(q => {
                if(q.deviceName) return q;
            });
        }
    },
    REMOVE_DEVICES(state, deviceNames) {
        deviceNames.forEach(deviceName => {
            const index = state.devices.findIndex(device => device.deviceName === deviceName);
            if (index > -1) {
                state.devices.splice(index, 1);
            }
        });
    }
}

const actions = {
    getDevices({commit}, User) { // TODO User: {username, role}
        $http.post("/v1/device/list", User).then(res => {
            if (res.data) {
                commit('SET_DEVICES', res.data);
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
