import { $http } from '@/plugins'

const state = {
  appList: []
}

const mutations = {
  SET_APP_LIST(state, list) {
    state.appList = list
  }
}

const actions = {
  getApplication({commit}) {
    $http.get('/v1/application/list').then(res => {
      commit('SET_APP_LIST', res.data);
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
