import { $http } from '@/plugins'

const state = {
  scriptList: []
}

const mutations = {
  SET_SCRIPT_LIST(state, list) {
    state.scriptList = list
  }
}

const actions = {
  getScript({commit}, AppName) {
    $http.post('/v1/script/list', {applicationID: AppName}).then(res => {
      if(res.data){commit('SET_SCRIPT_LIST', res.data);}
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
