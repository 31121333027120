import { $http } from '@/plugins'
const state = {
  textList: []
};

const mutations = {
  SET_TEXTS(state, list) {
    state.textList = list
  }
};

const actions = {
  getTextList({commit}, user) {
    $http.post('/v1/text/list', user).then(res => {
      if (res.code === 0) {
        if (user.role === 'root') {
          commit('SET_TEXTS', res.data);
        } else {
          commit('SET_TEXTS', res.data);
        }
      }
    })
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
