export default {
    //narrow bar
    "actionlist":                   "Action List",
    "appmanageri":                  "Application Manager",
    "termanageri" :                 "Terminal Manager",
    "copymanageri" :                "Copy Manager",
    "configurationi" :              "Configuration",
    "useri":                        "User",
    //Script Manage
    "search":                       "Search",
    "addscript":                    "Add Script",
    "scriptname":                   "Script Name",
    "scripttemplate":               "Script Template",
    "app":                          "Application",
    "scripttest":                   "Script Test",
    "environmentconfig":            "Environment Configuration",
    "testscript":                   "Test Script",
    "testresult":                   "Test End",
    "scriptcontent":                "Script Content",
    "plsinputcontent":              "Please input Script content",
    "userlist":                     "User List",
    "copytext":                     "Copy Text",
    "tagID":                        "Personal ID to tag in comment",
    "confirm":                      "Confirm",
    "newcomb":                      "New Combination",
    "openlist":                     "Open Script List",
    "scriptlist":                   "Script List",
    "chosecomb":                    "Choose Script to Combine",
    "combi":                        "Combine",
    "combiname":                    "Script Combination Name",
    "into1":                        "In to One",
    "y":                            "Yes",
    "n":                            "No",
    "Act":                          "Actions",
    "OL":                           "Only Like",
    "LaR":                          "Like and Reply",
    "RT":                           "Run time",
    //Application Manager
    "addappbtn":                    "Add New Application",
    "appnameth":                    "Application Name",
    "appplaceth":                   "Application Address",
    "appplatth":                    "Application Platform",
    "operth":                       "Operation",
    "editbtn":                      "Edit",
    "deletebtn":                    "Delete",
    "appinfolb":                    "Application Information",
    "appreferlb":                   "Application Reference",
    "plsselectevent" :              "Please select event area",
    "canclebtn":                    "Cancel",
    "acceptbtn":                    "OK",
    "editsuccessbtn":               "Save successfully",
    "deletemsg":                    "This operation will permanently delete the record, do you want to proceed",
    "hintmsg":                      "Hint message",
    "deletesuccess":                "Delete successfully",
    //Device View
    "chooseinput":                  "Please choose",
    "nodatatab":                    "No data",
    "startscriptbtn":               "Startup Device",
    "multiuserstartscriptbtn":      "Multi-user Script Startup",
    "countspan":                    "The number of robots running at the same time:",
    "unlimitli":                    "Unlimited",
    "denicknameth":                 "Device Nick Name",
    "denameth":                     "Device Name",
    "destatusth":                   "Device Status",
    "setupalias" :                  "Set Name",
    "settonormal" :                 "Set to Normal",
    "settoabnormal" :               "Set to Abnormal",
    "device":                       "Device",
    "totalacc":                     "Total Account",
    "enteralias":                   "Enter alias",
    "linkgroup":                    "Link group",
    "pausetime":                    "Approximate pause time per operation",
    "pausetimeoption":              "Calculated in seconds, it is recommended to set at least 120",
    "primaryaccount":               "Primary account name",
    "value":                        "Value",
    "errmsg":                       "The parameter is incorrect or the machine is not idle",
    "account":                      "Account name",
    "state":                        "Status",
    "numberofpublic":               "Number of published articles",
    "abnormal":                     "Abnormal state",
    "scriptexecution":              "Script execution",
    "normal":                       "Normal state",
    "issend":                       "Is Send to",
    "isset":                        "Is Set to",
    "loopCount":                    "Loop Count",
    "updatebtn":                    "Update",
    "disconnected":                 "Disconnected",
    "idle":                         "Ready",
    "running":                      "Running",
    "updating":                     "Updating",
    "random":                       "Random",
    "queue":                        "Queue",
    "checkenvtitle":                "The parameter has not been set, is it enforced?",
    "readymsg":                     "No Group is running!",
    "disconnectmsg":                "Device are disconnected or updating!",
    "setstatus":                    "Sending status",
    "set":                          "Set",
    "findnotset":                   "Find not Set",
    "inqueue":                      "Total device running in queue: ",
    "editqueue":                    "Edit Queue",
    "plat":                         "Platform",
    "contbtn":                      "Continue",
    "stopbtn":                      "Stop",
    "listcontent":                  "List",
    "totalq":                       "Total Queue",
    "delQtitle":                    "This operation will permanently delete this Queue out of this device, are you sure to proceed?",
    "notsetmsg":                    "Have not set Devices, total: ",
    //Text View
    "addcopybtn":                   "Add new copy",
    "copyconfig":                   "Copy Configuration",
    "copytextname":                 "Copy Text Name",
    "add":                          "Add new",
    "emotioncommand":               "Copy content(use`${0},${1},...,${4}`to fill in random emotion, maximum 5 emotions",
    "emotioninput":                 "'This is a string of ${0} emotion.' => 'This is a string of 😀 emotion.'",
    "upcontent":                    "Drop picture here or click to upload",
    "delPic":                       "Are you sure to remove ",
    "title":                        "Title: ",
    "delText":                      "This operation will permanently delete this Text forever! Are you sure to delete it?",
    //Config View
    "languageslb":                  "Languages: ",
    "chineseli":                    "Chinese",
    "englishli":                    "English",
    "save":                         "Save",
    "version":                      "Version",
    "logOut":                       "Log Out",
    // posts view
    "posts":                        "Posts",
    "NPost":                        "Normal",
    "APost":                        "Ads",
    "Lnickname":                    "Link Nickname",
    "SLink":                        "Sum Of Links",
    "CLink":                        "Choose Link",
    // Form view
    "createnew":                    "Create new user",
    "username":                     "Username: ",
    "password":                     "Password: ",
    "repass":                       "Re-Password: ",
    "name":                         "Name: ",
    "creator":                      "Creator: ",
    "create":                       "Create",
    "login":                        "Login",
    "role":                         "Role: ",
    "update":                       "Updated Time: ",
    "changepass":                   "Change Password",
    "current":                      "Current Password: ",
    "new":                          "New Password: ",
    // Account Manager
    "accmangager":                  "Account Manager",
    "accusername":                  "Account Username: ",
    "accpassword":                  "Account Password: ",
    "accnickname":                  "Account Nickname",
    "send":                         "Send to admin",
    "set":                          "Set to device",
    "sendgroup":                    "Send to group",
    "newacc":                       "New Account",
    "delAccTitle":                  "This action will permanently delete this account forever. Are you sure to proceed?",
    "totalAcc":                     "Total Account: ",
    "accinfo":                      "Account Information",
    "plsinputname":                 "Please input Account Name",
    "plsinputusername":             "Please input Account Username",
    "plsinputpassword":             "Please input Account Password",
    "trans":                        "Transfer Accounts",
    "from":                         "From",
    "to":                           "To",
    "normal_state":                 "Normal",
    "useless_state":                "Need to check",
    "out_state":                    "Logged Out",
    "lang_state":                   "Language is not English",
    "locked":                       "Locked",
    "author":                       "Authorize or Locked",
    "pic":                          "Authorize picture",
    "phone":                        "Authorize phone number",
    "disabled":                     "Disabled soon",
    "restrict":                     "Restricted for hours",
    "swlogin":                      "Save without login",
    "delacc":                       "Delete Account",
    "All":                          "All",
    "delMuchAccTitle":              "This action will permanently delete all this type of account. Are you sure to proceed?",
    "TransferTitle":                "This action will transfer all account from A to B, with A and B are described at the end. Are you sure to proceed?",
    "GrSearchTitle":                "Input or select for search!",
    //Group
    "group":                        "Group",
    "groupmanage":                  "Group Manager",
    "grname":                       "Group Name",
    "newgr":                        "New Group",
    "chooseacc":                    "Choose account",
    "delGrTitle":                   "This action will permanently delete this group forever. Are you sure to proceed?",
    "saveEditGr":                   "Save Group",
    "setDeviceP1":                  "Are you sure to set total ",
    "setDeviceP2":                  " Group to Device ",
    "editgr":                       "Edit Group",
    "AdmindelGrTitle":              "This action will only delete these groups that created by you. Are you sure to proceed?",
    //IP config
    "ipconfig":                     "IP Configuration: ",
    "pp":                           "Purpose: ",
    //Post
    "minreact":                     "Minimum Likes",
    "keys":                         "Key words",
    "url":                          "URL: ",
    "getting":                      "Get methods: ",
    "times":                        "Using times",
    "setTime":                      "Set Auto Delete",
    "delTitle":                     "Are you sure to delete this link?",
    "editTime":                     "Set Timer auto delete",
    "editLinks":                    "Edit Links Information",
    "deltimer":                     "Delete by time: ",
    "delusetime":                   "Delete base on the number of usage times: ",
    "deltimerholder":               "Calculated in days",
    "delusetimeholder":             "Calculated by the number of usage times",
    "saveTitle":                    "Are you sure to set",
    "delcon":                       "Delete Condition",
    "extra":                        "Extra Parameters",
    "extraDescribe":                "CODE1|CODE2|CODE3..."
}
