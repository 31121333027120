import { $http } from "@/plugins";
import vue from 'vue';
import i18n from "@/i18n";
const state ={
    accountList: {}
};

function getState(status, keys) {
    let state;
    switch (+status) {
        case -7:
            state = i18n.t('locked');
            break
        case -6:
            state = i18n.t('phone');
            break
        case -5:
            state = i18n.t('pic');
            break
        case -4:
            state = i18n.t('useless_state');
            break
        case -3:
            state = i18n.t('out_state');
            break
        case -2:
            state = i18n.t('author');
            break
        case -1:
            state = i18n.t('restrict');
            break
        case 0:
            state = i18n.t('normal_state');
            break
        case 1:
            state = i18n.t('lang_state');
            break
        case 2:
            state = i18n.t('disabled');
            break
    }
    return state
}

const mutations = {
    SET_ACCOUNTLIST(state, source) {
        var { key, data } = source;
        data = data.map(acc => {
            acc.status = getState(acc.status);
            return acc;
        })
        vue.set(state.accountList, key, data);
    },
    SET_ACCOUNT(state, account) {
        var key;
        if(account.role || account.creator){
            const role = account.role; 
            const creator = account.creator; account = account.acc;
            key = role != 'employee' ? account.appname + creator : account.appname + account.username;
        } else key = account.appname + account.username;
        const list = state.accountList[key] ? state.accountList[key] : [];
        account.status = typeof(account.status) == 'string' ? account.status : getState(account.status);
        const index = list.length ? list.findIndex(acc => acc.id === account.id) : -1;
        if (index > -1) {
            list.splice(index, 1);
        }
        list.unshift(account);
    },
    DEL_ACCOUNT(state, account) {
        if (account.id) {
            const key = account.appname + account.username;
            const list = state.accountList[key];
            const index = list.findIndex(acc => acc.id === account.id);
            if (index > -1) {
                list.splice(index, 1);
            }
        }
    }
}

const actions = {
    getAccount({commit, state}, ID){ // ID: {appname, username}
        return new Promise(resolve => {
            const { appname, username } = ID;
            const key = appname + username;
            if (!(state.accountList[key])) {
                $http.post("/v1/account/list", ID).then(res => {
                    if(res.data){
                        commit('SET_ACCOUNTLIST', {
                            key,
                            data: res.data
                        });
                        resolve();
                    }
                })
            } else {
                resolve()
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
