import { $http } from "@/plugins";
const state ={
    users: [],
    username: []
};

const mutations = {
    SET_USER(state, user) {
        state.users = user;
    },
    SET_USERNAME(state, user){
        for(let us of user){
            state.username.push({text: us.username, value: us.username})
        }
    }
}

const actions ={
    getuser({commit}, role){
        $http.post("/v2/accountlog/userinfo",{
            username: localStorage.un,
            acckey: localStorage.auth,
            role
        }).then(res => {
            if(res.data){
                commit('SET_USER', res.data);
                commit('SET_USERNAME', res.data);
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}