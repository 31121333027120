<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data(){
    return{
    }
  },
  created() {
    if(!localStorage.auth || !localStorage.un) {
      this.$router.replace({path: '/login'});
    }
  }

}
</script>

<style lang="less">
</style>
