import VueI18n from 'vue-i18n';
import Vue from 'vue';
import Element from 'element-ui'
import en from './en';
import cn from './chineselang';
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
Vue.use(VueI18n);

const messages = {
    en: {
      ...en,
      ...enLocale
    },
    cn: {
      ...cn,
      ...zhLocale
    }
};

const i18n = new VueI18n({
    locale: localStorage.local,
    fallbackLocale: 'en',
    messages,
});

if(localStorage.local){
  i18n.locale = localStorage.local;
}
else{
  localStorage.local = 'en';
  i18n.locale = localStorage.local;
}

Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})

export default i18n
