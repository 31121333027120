import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginPage.vue')
  },
  {
    path: '/',
    name: 'homepage',
    component: () => import(/* webpackChunkName: "homepage" */ '../views/HomepageView.vue'),
    children: [
      {
        path: '/script',
        name: 'script',
        component: () => import(/* webpackChunkName: "script" */ '../views/OrtherView/ScriptManage.vue')
      },
      {
        path: '/application',
        name: 'application',
        component: () => import(/* webpackChunkName: "application" */ '../views/OrtherView/ApplicationManage.vue')
      },
      {
        path: '/device',
        name: 'device',
        component: () => import(/* webpackChunkName: "device" */ '../views/OrtherView/DeviceView.vue')
      },
      {
        path: '/text',
        name: 'text',
        component: () => import(/* webpackChunkName: "text" */ '../views/OrtherView/TextView.vue')
      },
      {
        path: '/config',
        name: 'config',
        component: () => import(/* webpackChunkName: "config" */ '../views/OrtherView/ConfigView.vue')
      },
      {
        path: '/posts',
        name: 'posts',
        component: () => import(/* webpackChunkName: "posts" */ '../views/OrtherView/PostsView.vue')
      },
      {
        path: '/user',
        name: 'user',
        component: () => import(/* webpackChunkName: "selfpage" */ '../views/OrtherView/UserPage.vue')
      },
      {
        path: '/accmanager',
        name: 'accountmanager',
        component: () => import(/* webpackChunkName: "accmanager" */ '../views/OrtherView/AccountManagerView.vue')
      },
      {
        path: '/groups',
        name: 'groupmanager',
        component: () => import(/* webpackChunkName: "groups" */ '../views/OrtherView/GroupManagerView.vue')
      },
      {
        path: '/ipconfig',
        name: 'ipconfig',
        component: () => import(/* webpackChunkName: "ipconfig" */ '../views/OrtherView/IPConfigPage.vue')
      }
    ]
  }
  
]

const router = new VueRouter({
  routes
})

export default router
