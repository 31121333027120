export default {
    //narrow bar
    "actionlist":                   "行为列表",
    "appmanageri":                  "应用管理",
    "termanageri" :                 "终端管理",
    "copymanageri" :                "文案管理",
    "configurationi" :              "配置",
    "useri":                        "用户",
    //Script Manage
    "search":                       "搜索",
    "addscript":                    "添加脚本",
    "scriptname":                   "脚本名称",
    "scripttemplate":               "脚本模板",
    "app":                          "应用",
    "scripttest":                   "脚本测试",
    "environmentconfig":            "环境配置",
    "testscript":                   "测试脚本",
    "testresult":                   "结束测试",
    "scriptcontent":                "脚本内容",
    "plsinputcontent":              "请输入内容",
    "userlist":                     "用户列表",
    "copytext":                     "文案",
    "tagID":                        "评论中的标签的ID",
    "confirm":                      "确认",
    "newcomb":                      "新组合",
    "openlist":                     "脚本列表",
    "scriptlist":                   "脚本列表",
    "chosecomb":                    "选择脚本来组合",
    "combi":                        "组合",
    "combiname":                    "脚本组合名称",
    "into1":                        "组合成一个",
    "y":                            "好",
    "n":                            "不",
    "Act":                          "操作",
    "OL":                           "只点赞",
    "LaR":                          "点赞和回复",
    "RT":                           "跑多久",
    //Application Manager
    "addappbtn":                    "添加应用",
    "appnameth":                    "应用名称",
    "appplaceth":                   "应用地址",
    "appplatth":                    "应用平台",
    "operth":                       "操作",
    "editbtn":                      "编辑",
    "deletebtn":                    "删除",
    "appinfolb":                    "应用信息",
    "appreferlb":                   "应用引用",
    "plsselectevent" :              "请选择活动区域",
    "canclebtn":                    "取消",
    "acceptbtn":                    "确定",
    "editsuccessbtn":               "保存成功!",
    "deletemsg":                    "此操作将永久删除该记录, 是否继续?",
    "hintmsg":                      "提示",
    "deletesuccess":                "删除成功",
    //Device View
    "chooseinput" :                 "请选择",
    "nodatatab" :                   "暂无数据",
    "startscriptbtn" :              "启动脚本",
    "multiuserstartscriptbtn" :     "多用户脚本启动",
    "countspan" :                   "机器人同时运行数量: ",
    "unlimitli" :                   "无限制",
    "denicknameth" :                "设备昵称",
    "denameth" :                    "设备名称",
    "destatusth" :                  "设备状态",
    "setupalias" :                  "设置别名",
    "settonormal" :                 "设为状态正常",
    "settoabnormal" :               "设为状态异常",
    "device":                       "设备",
    "totalacc":                     "一共有账户",
    "enteralias":                   "请输入别名",
    "linkgroup":                    "链接",
    "pausetime":                    "每个操作大约停顿时间",
    "pausetimeoption":              "以秒计算, 建议至少设置120",
    "primaryaccount":               "主账号名称",
    "value":                        "数值",
    "errmsg":                       "参数不正确或者机器未空闲",
    "account":                      "账号",
    "state":                        "状态",
    "numberofpublic":               "已发布文章数量",
    "abnormal":                     "异常",
    "scriptexecution":              "脚本执行中",
    "normal":                       "正常",
    "loopCount":                    "循环次数",
    "issend":                       "是发送到",
    "isset":                        "是给",
    "updatebtn":                    "更新",
    "disconnected":                 "断开连接",
    "idle":                         "待机",
    "running":                      "运行中",
    "updating":                     "更新中",
    "random":                       "随机",
    "queue":                        "队列",
    "checkenvtitle":                "参数未设置完成,是否强制执行？",
    "readymsg":                     "没有组在运行！",
    "disconnectmsg":                "设备已断开连接或正在更新！",
    "setstatus":                    "设置的状态",
    "set":                          "设置",
    "findnotset":                   "找还没设置",
    "inqueue":                      "队列中运行的设备总数：",
    "editqueue":                    "编辑队列",
    "plat":                         "平台",
    "contbtn":                      "继续",
    "stopbtn":                      "停",
    "listcontent":                  "列表",
    "totalq":                       "队列总数",
    "delQtitle":                    "此操作将从该设备中永久删除此队列，你确定要继续吗?",
    "notsetmsg":                    "设备还没有设置, 总数: ",
    //Text View
    "addcopybtn":                   "新增文案",
    "copyconfig":                   "文案配置",
    "copytextname":                 "文案名称",
    "add":                          "新增",
    "emotioncommand":               "文案内容(使用`${0},${1},...,${4}`来填入随机字符, 最多可以有5个随机字符",
    "emotioninput":                 "'这是一串随机${0}字符.' => '这是一串随机$@!%字符.'",
    "upcontent":                    "将图片拖放到此处或单击以上传",
    "delPic":                       "你是否要消除 ",
    "title":                        "标题: ",
    "delText":                      "此操作将永久删除该文案, 你确定要继续吗?",
    //Config View
    "languageslb":                  "语言：",
    "chineseli":                    "中文",
    "englishli":                    "英文",
    "save":                         "保存",
    "version":                      "版本",
    "logOut":                       "登出",
    // posts view
    "posts":                        "帖子",
    "NPost":                        "平常",
    "APost":                        "广告",
    "Lnickname":                    "链接呢名",
    "SLink":                        "连接总数",
    "CLink":                        "选链接",
    // Form view
    "createnew":                    "创建新用户",
    "username":                     "用户: ",
    "password":                     "密码: ",
    "repass":                       "输入密码: ",
    "name":                         "名字: ",
    "creator":                      "创造者: ",
    "create":                       "创建",
    "login":                        "登录",
    "role":                         "角色: ",
    "update":                       "更新时间: ",
    "changepass":                   "更改密码",
    "current":                      "当前密码: ",
    "new":                          "新密码：",
    // Account Manager
    "accmangager":                  "用户经理",
    "accusername":                  "账号的用户名: ",
    "accpassword":                  "账号的密码: ",
    "accnickname":                  "账号昵称",
    "send":                         "给admin",
    "set":                          "发送设备",
    "sendgroup":                    "添加到组",
    "newacc":                       "创建新用户",
    "delAccTitle":                  "此操作会将此帐户永远删除。你确定要继续吗？",
    "totalAcc":                     "总数账号: ",
    "accinfo":                      "账号信息",
    "plsinputname":                 "请输入账号的名称",
    "plsinputusername":             "请输入账号的用户名",
    "plsinputpassword":             "请输入账号的密码",
    "trans":                        "转帐号",
    "from":                         "从",
    "to":                           "到",
    "normal_state":                 "正常",
    "useless_state":                "需要检查",
    "out_state":                    "被登出",
    "lang_state":                   "语言不是英文",
    "locked":                       "被锁住",
    "author":                       "要证实或被锁住",
    "pic":                          "要证实照片",
    "phone":                        "要证实电话号码",
    "disabled":                     "快的被禁用",
    "restrict":                     "限制几个小时",
    "swlogin":                      "保存不登陆",
    "delacc":                       "账号删除",
    "All":                          "全部",
    "delMuchAccTitle":              "此操作会将此所有的这种状态的帐户永远删除。你确定要继续吗？",
    "TransferTitle":                "此操作会将所有帐户从 A 转移到 B，A 和 B 如最后所述。 你确定要继续吗？",
    "GrSearchTitle":                "填或选来找",
    //Group
    "group":                        "组",
    "groupmanage":                  "组经理",
    "grname":                       "组名称",
    "newgr":                        "添加新组",
    "chooseacc":                    "选择账号",
    "delGrTitle":                   "此操作将永远永久删除此组。你确定要继续吗？",
    "saveEditGr":                   "保存这个组",
    "setDeviceP1":                  "你决定要设定总数 ",
    "setDeviceP2":                  " 组给设备",
    "editgr":                       "编辑组",
    "AdmindelGrTitle":              "此操作将只删除你创建的组。你确定要继续吗？",
    //IP config
    "ipconfig":                     "IP配置: ",
    "pp":                           "目的: ",
    //Post
    "minreact":                     "点赞最小量",
    "keys":                         "关键字",
    "url":                          "网址: ",
    "getting":                      "拿方法: ",
    "times":                        "用过多少次： ",
    "setTime":                      "设置删除时间",
    "delTitle":                     "你决定要删除这个链接？",
    "editTime":                     "设置时间自己删除",
    "editLinks":                    "设置链接信息",
    "deltimer":                     "被时间抹去: ",
    "delusetime":                   "根据使用次数删除: ",
    "deltimerholder":               "按天计算",
    "delusetimeholder":             "以使用次数计算",
    "saveTitle":                    "你决定要设置",
    "delcon":                       "删除条件",
    "extra":                        "额外参数",
    "extraDescribe":                "代码1|代码2|代码3..."
}
