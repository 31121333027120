import axios from 'axios'

const baseURL = process.env.VUE_APP_HOST

const service = axios.create({
    baseURL
})

export default function (Vue) {
    let loading;
    service.interceptors.request.use(config => {
        loading = Vue.prototype.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        return config
    })

    service.interceptors.response.use(
        response => {
            const result = response.data
            if (result.code >= 400 && result.message){
                Vue.prototype.$message({
                    message: result.message,
                    type: 'error'
                });
            }
            if (result.code != 400 && result.code != 401 && result.message) {
                Vue.prototype.$message({
                    message: result.message,
                    type: 'warning'
                });
            }
            if (result.code === 401) {
                localStorage.removeItem("un");
                localStorage.removeItem("auth");
                window.location.replace('/');
            }
            loading.close()
            return result
        }, error => {
            console.log("err", error.response);
            if(error.response){
                if (error.response.data.statusCode === 429) {
                    Vue.prototype.$message({
                        message: "IP is not exist! Please contact to admin/root to add your IP",
                        type: 'error'
                    });
                    localStorage.removeItem("un");
                    localStorage.removeItem("auth");
                    window.location.replace('/');
                }
            }
            // return Promise.reject(error);
        }
    )

    Vue.prototype.$http = service;

    Vue.prototype.$baseURL = baseURL;

    Vue.prototype.$load = {
        open() {
            loading = Vue.prototype.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        close() {
            loading.close()
        }
    };
}

export const $http = service;
export const $baseURL = baseURL;
