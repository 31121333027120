import Vue from 'vue'
import Vuex from 'vuex'
import device from './modules/device'
import application from './modules/application'
import script from './modules/script'
import texts from './modules/texts'
import usershow from './modules/usershow'
import config from './modules/config'
import ip from './modules/ip'
import group from './modules/group'
import account from './modules/account'
import links from './modules/links'
import scriptcombine from './modules/scriptcombine'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    device,
    application,
    script,
    texts,
    usershow,
    config,
    ip,
    group,
    account,
    links,
    scriptcombine
  }
})
