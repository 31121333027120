import { $http } from "@/plugins";
const state = {
    iplist: []
};

const mutations = {
    SET_IP(state, ip) {
        state.iplist = ip;
    }
};

const actions = {
    getip({commit}, role){
        $http.post("/v1/ip/list", {
            role,
            username: localStorage.un
        }).then(res => {
            if(res.data){
                commit('SET_IP', res.data);
            }
        })
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}