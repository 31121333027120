import { $http } from '@/plugins'

const state = {
  combineList: []
}

const mutations = {
  SET_COMBINE_LIST(state, list) {
    state.combineList = list
  }
}

const actions = {
  getCombine({commit}, AppName) {
    $http.post('/v1/scriptcombine/list', {appname: AppName}).then(res => {
      if(res.data){commit('SET_COMBINE_LIST', res.data);}
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
